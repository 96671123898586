import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import ApplicationContext from '../../ApplicationContext.js';

import UpdateItemInCartForm from '../ToolDetailsPage/UpdateItemInCartForm.jsx';

import {
  ChevronRight,
  ChevronLeft,
  ChevronDown,
  ChevronUp,
  Minus,
} from 'react-feather';

const RowContainer = styled.div`
  font-family: Univers;
  width: 98%;
  display: flex;

  @media (max-width: 1024px) {
    width: 100%;
    flex-direction: column;
  }
`;

const Row = styled.div`
  font-family: Univers;
  width: 90%;
  padding: 0px;

  border-bottom: ${(props) =>
    props.$isLastItem ? 'none' : '1px solid var(--color-gray4)'};
  @media (max-width: 1024px) {
    width: 100%;
  }
`;

const DescriptionContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 8px 0 24px 18px;
  font-size: 12px;
  flex-grow: 1;

  @media (max-width: 1024px) {
    margin: 0px;
    font-size: 12px;
  }
`;

const FeatureNameAndDescriptionContainer = styled.div`
  display: flex;

  @media (max-width: 1024px) {
    display: grid;
    grid-template-columns: max-content auto;
    column-gap: 18px;
  }
`;

const ColumnContainer = styled.div`
  display: flex;
`;

const ColumnOne = styled.div`
  width: 40%;
  margin-right: 8px;
`;

const ColumnTwo = styled.div`
  width: 40%;
  margin-right: 18px;
`;

const ToolNameButton = styled.button`
  font-family: Univers;
  color: var(--color-gray1);
  font-weight: 500;
  font-size: 18px;
  text-transform: uppercase;
  margin-top: 10px;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  text-align: left;
  text-transform: lowercase;

  &:first-letter {
    text-transform: uppercase;
  }

  @media (max-width: 1024px) {
    font-weight: 500;
    font-size: 12px;
    cursor: default;
    margin-bottom: 6px;
  }
`;

const FeatureName = styled.div`
  width: ${(props) => (props.$isExpanded ? '50%' : '19.75%')};
  color: var(--color-gray2);
  font-weight: 500;
  text-transform: capitalize;
  font-size: 12px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-right: 6px;

  @media (max-width: 1024px) {
    width: unset;
    text-align: right;
  }
`;

const FeatureDescription = styled.div`
  width: ${(props) => (props.$isExpanded ? '50%' : '29.75%')};
  color: var(--color-gray1);
  word-break: break-word;
  text-transform: uppercase;
  font-style: ${(props) => (!props.$isEmpty ? 'italic' : '')};
  font-size: 12px;
  display: ${(props) => (!props.$isMore ? '' : 'flex')};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  @media (max-width: 1024px) {
    width: unset;
  }
`;

const MoreLessButton = styled.button`
  font-family: Univers;
  display: flex;
  align-items: center;
  align-self: baseline;
  padding-bottom: 10px;
  color: var(--color-primary);
  font-size: 12px;
  font-weight: 500;
  background: none;
  border: none;
  margin: 0;
  margin-left: ${(props) => (props.$isSecondColumn ? 'auto' : '24px')};
  padding: 0;
  cursor: pointer;
`;

const StyledChevronRight = styled(ChevronRight)`
  margin-left: -6px;
`;

const StyledChevronLeft = styled(ChevronLeft)`
  margin-right: -6px;
`;

const StyledChevronDown = styled(ChevronDown)`
  margin-top: 2px;
`;

const StyledChevronUp = styled(ChevronUp)`
  margin-top: 2px;
`;

const ToolsContainer = styled.div`
  display: flex;
  width: 100%;
  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

const FavoritesAndUpdateShoppingCartContainer = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

const RemoveFromFavoritesButton = styled.button`
  display: flex;
  border: none;
  background: none;
  cursor: pointer;
  font-family: Univers;
  margin: 10px 0px 0px 12px;
  opacity: ${(props) => props.$isLoading && '0.5'};
  width: 120px;
  padding: 0;

  @media (max-width: 1024px) {
    margin: 0;
  }
`;

const InnerFavoritesButtonContainer = styled.div`
  display: flex;
  align-items: center;
`;

const RemoveFromFavoritesButtonText = styled.span`
  color: var(--color-primary);
  font-size: 14px;
  font-weight: 600;
  padding-left: 2px;
  font-style: ${(props) => props.$isLoading && 'italic'};
`;

const RemoveAndMoreButtonMobileContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 18px;
`;

const MobileMoreLessButton = styled.button`
  font-family: Univers;
  display: flex;
  align-items: center;
  padding-bottom: 10px;
  color: var(--color-primary);
  font-size: 14px;
  font-weight: 600;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
`;

const MobileContainer = styled.div`
  display: grid;
  grid-template-columns: 16% 30%;
  column-gap: 8px;

  @media (max-width: 400px) {
    grid-template-columns: 40% 55%;
  }
`;
const UpdateItemInCartContainer = styled.div`
  margin-top: 35px;
  margin-bottom: 0px;
  padding: 0px 4px;
  box-sizing: border-box;
  min-width: 232px;
  width: 25%;

  @media (max-width: 1024px) {
    margin-top: 8px;
    margin-bottom: 15px;
    padding: 0px;
    display: flex;
    justify-content: end;
  }
`;

const FavoritesToolRow = ({
  favoritesData,
  removeFromFavorites,
  isLoadingRemoveItemsFromFavorites,
  isLastItem,
}) => {
  const [isRowExpanded, setIsRowExpanded] = useState(false);
  const [isMobileRowExpanded, setIsMobileRowExpanded] = useState(false);
  const { classType } = useContext(ApplicationContext);

  const isMobile = classType === 'compact';

  const navigate = useNavigate();

  const capitalizeWords = (str) => {
    return str?.toLowerCase().replace(/(?:^|\s)\w/g, (match) => {
      return match.toUpperCase();
    });
  };

  const redirectToToolDetailsPage = () => {
    navigate(`/tool/${favoritesData.SapNumber}`);
  };

  const onMoreLessClick = () => {
    setIsRowExpanded((prev) => !prev);
  };

  const onMobileMoreLessButtonClick = () => {
    setIsMobileRowExpanded((prev) => !prev);
  };

  return (
    <RowContainer>
      {isMobile ? (
        <RemoveAndMoreButtonMobileContainer>
          <RemoveFromFavoritesButton
            disabled={isLoadingRemoveItemsFromFavorites}
            $isLoading={isLoadingRemoveItemsFromFavorites}
            onClick={() => removeFromFavorites()}
          >
            <InnerFavoritesButtonContainer>
              {' '}
              <Minus size={14} strokeWidth={4} color={'var(--color-primary)'} />
              <RemoveFromFavoritesButtonText
                $isLoading={isLoadingRemoveItemsFromFavorites}
              >
                {isLoadingRemoveItemsFromFavorites ? 'Removing ...' : 'Remove'}
              </RemoveFromFavoritesButtonText>
            </InnerFavoritesButtonContainer>
          </RemoveFromFavoritesButton>
          <MobileMoreLessButton onClick={onMobileMoreLessButtonClick}>
            <div>{isMobileRowExpanded ? 'Less' : 'More'}</div>
            {isMobileRowExpanded ? (
              <StyledChevronUp strokeWidth={3} height={18} />
            ) : (
              <StyledChevronDown strokeWidth={3} height={18} />
            )}
          </MobileMoreLessButton>
        </RemoveAndMoreButtonMobileContainer>
      ) : (
        <RemoveFromFavoritesButton
          disabled={isLoadingRemoveItemsFromFavorites}
          $isLoading={isLoadingRemoveItemsFromFavorites}
          onClick={() => removeFromFavorites()}
        >
          {' '}
          <InnerFavoritesButtonContainer>
            {' '}
            <Minus size={14} strokeWidth={4} color={'var(--color-primary)'} />
            <RemoveFromFavoritesButtonText
              $isLoading={isLoadingRemoveItemsFromFavorites}
            >
              {isLoadingRemoveItemsFromFavorites ? 'Removing ...' : 'Remove'}
            </RemoveFromFavoritesButtonText>
          </InnerFavoritesButtonContainer>
        </RemoveFromFavoritesButton>
      )}
      <Row $isLastItem={isLastItem}>
        <ToolNameButton onClick={redirectToToolDetailsPage}>
          {favoritesData.Name || 'Tool Name N/A'}
        </ToolNameButton>
        <FavoritesAndUpdateShoppingCartContainer>
          <ToolsContainer>
            <DescriptionContainer $isExpanded={isRowExpanded}>
              <>
                {isRowExpanded ? (
                  <ColumnContainer>
                    <ColumnOne>
                      <FeatureNameAndDescriptionContainer>
                        <FeatureName $isExpanded>Material Number </FeatureName>
                        <FeatureDescription
                          $isExpanded
                          $isEmpty={!!favoritesData.SapNumber}
                        >
                          {favoritesData.SapNumber}{' '}
                        </FeatureDescription>
                      </FeatureNameAndDescriptionContainer>
                      <FeatureNameAndDescriptionContainer>
                        <FeatureName $isExpanded>Legacy Number </FeatureName>
                        <FeatureDescription
                          $isExpanded
                          $isEmpty={!!favoritesData.LegacyNumber}
                        >
                          {favoritesData.LegacyNumber || 'N/A'}{' '}
                        </FeatureDescription>
                      </FeatureNameAndDescriptionContainer>
                      {favoritesData.DescriptionLite.slice(0, 5).map(
                        (description) => (
                          <React.Fragment
                            key={`${
                              favoritesData?.SapNumber +
                              description.Value +
                              description.Name
                            }`}
                          >
                            <FeatureNameAndDescriptionContainer>
                              <FeatureName $isExpanded>
                                {capitalizeWords(description.Name)}
                              </FeatureName>
                              <FeatureDescription
                                $isExpanded
                                $isEmpty={!!description.Value}
                              >
                                {description.Value || 'N/A'}
                              </FeatureDescription>
                            </FeatureNameAndDescriptionContainer>
                          </React.Fragment>
                        )
                      )}
                    </ColumnOne>
                    <ColumnTwo>
                      {favoritesData.DescriptionLite.slice(5, 11).map(
                        (description) => (
                          <React.Fragment
                            key={`${
                              favoritesData?.SapNumber +
                              description.Value +
                              description.Name
                            }`}
                          >
                            <FeatureNameAndDescriptionContainer>
                              <FeatureName $isExpanded $isSecondColumn>
                                {capitalizeWords(description.Name)}
                              </FeatureName>
                              <FeatureDescription
                                $isExpanded
                                $isSecondColumn
                                $isEmpty={!!description.Value}
                              >
                                {description.Value || 'N/A'}
                              </FeatureDescription>
                            </FeatureNameAndDescriptionContainer>
                          </React.Fragment>
                        )
                      )}
                    </ColumnTwo>
                    {isRowExpanded && (
                      <MoreLessButton $isSecondColumn onClick={onMoreLessClick}>
                        <StyledChevronLeft strokeWidth={3} height={12} />
                        <div>Less</div>
                      </MoreLessButton>
                    )}
                  </ColumnContainer>
                ) : (
                  <>
                    {isMobile ? (
                      <MobileContainer>
                        {' '}
                        <FeatureName>Material Number </FeatureName>
                        <FeatureDescription
                          $isEmpty={!!favoritesData.SapNumber}
                        >
                          {favoritesData.SapNumber}{' '}
                        </FeatureDescription>
                        <FeatureName>Legacy Number </FeatureName>
                        <FeatureDescription
                          $isEmpty={!!favoritesData.LegacyNumber}
                        >
                          {favoritesData.LegacyNumber || 'N/A'}{' '}
                        </FeatureDescription>
                        {isMobileRowExpanded
                          ? favoritesData.DescriptionLite.map((description) => (
                              <React.Fragment
                                key={`${
                                  favoritesData?.SapNumber +
                                  description.Value +
                                  description.Name
                                }`}
                              >
                                <FeatureName>
                                  {capitalizeWords(description.Name)}
                                </FeatureName>
                                <FeatureDescription
                                  $isEmpty={!!description.Value}
                                >
                                  {description.Value || 'N/A'}
                                </FeatureDescription>
                              </React.Fragment>
                            ))
                          : favoritesData.DescriptionLite.slice(0, 2).map(
                              (description) => (
                                <React.Fragment
                                  key={`${
                                    favoritesData?.SapNumber +
                                    description.Value +
                                    description.Name
                                  }`}
                                >
                                  <FeatureName>
                                    {capitalizeWords(description.Name)}
                                  </FeatureName>
                                  <FeatureDescription
                                    $isEmpty={!!description.Value}
                                  >
                                    {description.Value || 'N/A'}
                                  </FeatureDescription>
                                </React.Fragment>
                              )
                            )}
                      </MobileContainer>
                    ) : (
                      <>
                        <FeatureNameAndDescriptionContainer>
                          <FeatureName>Material Number </FeatureName>
                          <FeatureDescription
                            $isEmpty={!!favoritesData.SapNumber}
                            $isMore
                          >
                            {favoritesData.SapNumber} {/* {!isMobile && ( */}
                            <MoreLessButton onClick={onMoreLessClick}>
                              <div>More</div>
                              <StyledChevronRight strokeWidth={3} height={12} />
                            </MoreLessButton>
                            {/* )} */}
                          </FeatureDescription>
                        </FeatureNameAndDescriptionContainer>
                        <FeatureNameAndDescriptionContainer>
                          <FeatureName>Legacy Number </FeatureName>
                          <FeatureDescription
                            $isEmpty={!!favoritesData.LegacyNumber}
                          >
                            {favoritesData.LegacyNumber || 'N/A'}{' '}
                          </FeatureDescription>
                        </FeatureNameAndDescriptionContainer>
                        {favoritesData.DescriptionLite.slice(
                          0,
                          isMobile ? 2 : 5
                        ).map((description) => (
                          <React.Fragment
                            key={`${
                              favoritesData?.SapNumber +
                              description.Value +
                              description.Name
                            }`}
                          >
                            <FeatureNameAndDescriptionContainer>
                              <FeatureName>
                                {capitalizeWords(description.Name)}
                              </FeatureName>
                              <FeatureDescription
                                $isEmpty={!!description.Value}
                              >
                                {description.Value || 'N/A'}
                              </FeatureDescription>
                            </FeatureNameAndDescriptionContainer>
                          </React.Fragment>
                        ))}
                      </>
                    )}
                  </>
                )}
              </>
            </DescriptionContainer>
          </ToolsContainer>
          <UpdateItemInCartContainer>
            <UpdateItemInCartForm
              toolData={favoritesData}
              isDifferentDataFormat
              buttonWidth={'126px'}
              buttonHeight={'32px'}
              oktaRedirectOnLoginTo={`/favorites`}
            />
          </UpdateItemInCartContainer>
        </FavoritesAndUpdateShoppingCartContainer>
      </Row>
    </RowContainer>
  );
};

export default FavoritesToolRow;
